import React, {useEffect, useRef} from 'react';

import {useNotifications} from "../../../providers/NotificationsProvider";
import {useApi} from "../../../providers/ApiProvider";
import {Link, useParams} from "react-router-dom";
import CampaignsModel from "../../../models/CampaignsModel";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import Grades from "../../../helpers/Grades";
import {useMeta} from "../../../providers/MetaProvider";
import ProjectsModel from "../../../models/ProjectsModel";
import ProjectRulesModel from "../../../models/ProjectRulesModel";

export default function ProjectRulesList({}) {
    const metaContext = useMeta();
    const params = useParams();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const projectsRulesModel = new ProjectRulesModel(apiContext.api, params.projectId);
    const notifications = useNotifications();

    const [project, setProject] = React.useState({});
    const [rules, setRules] = React.useState([]);
    const [loading, setLoading] = React.useState(true);


    const loadData = () => {
        setLoading(true);

        projectsModel.view(params.projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                setProject(response);

                metaContext.update({
                    title: response.title + ' - Rules'
                });
            }

            projectsRulesModel.list().then((response) => {
                if (response !== false) {
                    setRules(response);
                }
            }).finally(() => {
                setLoading(false);
            });
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Loading error', 'error');
        }
    }


    useEffect(() => {
        loadData();
    }, []);


    const onDelete = (e, id) => {
        e.preventDefault();
        setLoading(true);

        projectsRulesModel.delete(id).then((response) => {
            if (response !== false) {
                loadData();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    return (
        <>
            <div className="d-flex flex-row justify-content-start m-block-gap">
                <Link to={'/p/'+params.projectId} className="btn btn-light-primary text-primary">Back to the project</Link>
            </div>

            <Link to={'/p/'+params.projectId+'/rules/create'} className="btn btn-light-success text-success mb-3">Add a new rule</Link>

            <div className="table-responsive rounded-2">
                <table className="table border customize-table mb-0 align-middle">
                    <thead className="text-dark fs-4">
                        <tr>
                            <th>
                                <h6 className={'fs-4 fw-semibold mb-0'}>
                                    Name
                                </h6>
                            </th>
                            <th style={{width: '160px'}}><h6 className="fs-4 fw-semibold mb-0"></h6></th>
                        </tr>
                    </thead>
                    <tbody>
                    {rules && rules.length ? rules.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div className="d-flex gap-1 flex-column">
                                        <Link to={'/p/' + params.projectId + '/rules/' + item.id} className="d-block">{item.name}</Link>
                                        {item.description ? (<div className="text-muted fs-3">{item.description}</div>) : ''}
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex justify-content-end gap-3">
                                        <button className="btn btn-circle btn-light-warning text-warning" onClick={(e) => onDelete(e, item.id)}>
                                            <i className="ti ti-trash fs-4"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    }) : (
                        <tr>
                            <td colSpan="2">
                                <div className="text-center fs-2 mt-4">
                                    No rules found.
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </>
    );
}