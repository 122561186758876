import React, {useEffect} from 'react';

import {Link} from "react-router-dom";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import Grades from "../../../helpers/Grades";

export default function ProjectHighlights({project, campaigns}) {
    const [reach, setReach] = React.useState(0);
    const [impressions, setImpressions] = React.useState(0);
    const [clicks, setClicks] = React.useState(0);
    const [spend, setSpend] = React.useState(0);
    const [currency, setCurrency] = React.useState('');
    const [ctr, setCtr] = React.useState(0);


    useEffect(() => {
        if (project && project.insights) {
            setReach(project.insights.reach ? project.insights.reach : 0);
            setImpressions(project.insights.impressions ? project.insights.impressions : 0);
            setClicks(project.insights.clicks ? project.insights.clicks : 0);
            setSpend(project.insights.spend ? project.insights.spend : 0);
            setCurrency(project.insights.account_currency ? project.insights.account_currency : '');
            setCtr(project.insights.ctr ? project.insights.ctr : 0);
        }
    }, [project]);


    return project && (
        <div className="px-3 m-block-gap">
            <div className="d-flex flex-row gap-3 align-items-stretch justify-content-stretch">
                <div className="card border-0 zoom-in bg-light-danger shadow-none w-100 mb-3">
                    <div className="card-body">
                        <div className="text-center">
                            <p className="fw-semibold fs-3 text-danger mb-1">Active Campaigns</p>
                            <h5 className="fw-semibold text-danger mb-0">{campaigns.length}</h5>
                        </div>
                    </div>
                </div>

                <div className="card border-0 zoom-in bg-light-success shadow-none w-100 mb-3">
                    <div className="card-body">
                        <div className="text-center">
                            <p className="fw-semibold fs-3 text-success mb-1">Spend{currency ? ', '+currency : ''}</p>
                            <h5 className="fw-semibold text-success mb-0">{spend}</h5>
                        </div>
                    </div>
                </div>

                <div className="card border-0 zoom-in bg-light-primary shadow-none w-100 mb-3">
                    <div className="card-body">
                        <div className="text-center">
                            <p className="fw-semibold fs-3 text-primary mb-1">CTR</p>
                            <h5 className="fw-semibold text-primary mb-0">{Math.round(ctr * 10) / 10} %</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-row gap-3 align-items-stretch justify-content-stretch">
                <div className="card border-0 zoom-in bg-light-info shadow-none w-100 mb-3">
                    <div className="card-body">
                        <div className="text-center">
                            <p className="fw-semibold fs-3 text-info mb-1">Impressions</p>
                            <h5 className="fw-semibold text-info mb-0">{impressions}</h5>
                        </div>
                    </div>
                </div>

                <div className="card border-0 zoom-in bg-light shadow-none w-100 mb-3">
                    <div className="card-body">
                        <div className="text-center">
                            <p className="fw-semibold fs-3 text-muted mb-1">Reach</p>
                            <h5 className="fw-semibold text-muted mb-0">{reach}</h5>
                        </div>
                    </div>
                </div>

                <div className="card border-0 zoom-in bg-light-warning shadow-none w-100 mb-3">
                    <div className="card-body">
                        <div className="text-center">
                            <p className="fw-semibold fs-3 text-warning mb-1">Clicks</p>
                            <h5 className="fw-semibold text-warning mb-0">{clicks}</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-end fs-2 text-muted">Actual stats for last 7 days.</div>
        </div>
    );
}
