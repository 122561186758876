import BaseCrudModel from "./BaseCrudModel";

/**
 * Campaigns model
 *
 * @param {ApiHelper} api
 */
export default class CampaignsModel extends BaseCrudModel {
    baseUrl = '/projects/{%PROJECT_ID%}/campaigns';

    constructor(api, projectId) {
        super(api);
        this.baseUrl = this.baseUrl.replace('{%PROJECT_ID%}', projectId);
    }


    async getTargetingSpecs(errorHandler) {
        const url = this.baseUrl + '/get-targeting-specs';
        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async refreshTargetingSpecs(errorHandler) {
        const url = this.baseUrl + '/refresh-targeting-specs';
        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async createCampaign(errorHandler) {
        const url = this.baseUrl + '/create';
        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async list(filter = null, sorting =  null, archived = null, errorHandler) {
        let url = this.baseUrl;
        let query = {};
        if (filter) {
            query.filter = filter;
        }
        if (sorting) {
            query.sort = sorting.field;
            query.direction = sorting.direction;
        }
        if (archived !== null) {
            query.archived = archived;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }

        const response = await this.api.getRequest(url);
        return this.processResponse(response, errorHandler);
    }


    async refresh(campaignId, errorHandler) {
        const url = this.baseUrl + '/' + campaignId + '/refresh';
        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }
}