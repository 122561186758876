import BaseCrudModel from "./BaseCrudModel";

/**
 * ProjectRules model
 *
 * @param {ApiHelper} api
 */
export default class ProjectRulesModel extends BaseCrudModel {
    baseUrl = '/projects/{%PROJECT_ID%}/rules';

    constructor(api, projectId) {
        super(api);
        this.baseUrl = this.baseUrl.replace('{%PROJECT_ID%}', projectId);
    }


    async specifications(errorHandler) {
        const url = this.baseUrl + '/specifications';
        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async copy(projectId, errorHandler) {
        const url = this.baseUrl + '/' + projectId + '/copy';

        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }
}