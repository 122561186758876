import React, {useEffect, useRef} from 'react';

import {useNotifications} from "../../providers/NotificationsProvider";
import {useApi} from "../../providers/ApiProvider";
import {Link, useParams} from "react-router-dom";
import CampaignsModel from "../../models/CampaignsModel";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";
import Grades from "../../helpers/Grades";
import {useMeta} from "../../providers/MetaProvider";
import ProjectsModel from "../../models/ProjectsModel";
import SimpleCardContainer from "../ui/SimpleCardContainer";

export default function CampaignCreate({}) {
    const metaContext = useMeta();
    const {projectId} = useParams();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const campaignsModel = new CampaignsModel(apiContext.api, projectId);
    const notifications = useNotifications();

    const [project, setProject] = React.useState({});
    const [adsetId, setAdsetId] = React.useState(null);
    const [targetingSpecs, setTargetingSpecs] = React.useState({});
    const [loading, setLoading] = React.useState('general');


    const loadData = () => {
        setLoading('general');

        projectsModel.view(projectId).then((response) => {
            if (response !== false) {
                setProject(response);

                getTargetingSpecs();
            }
        }).finally(() => {
            setLoading('');
        });
    }


    const getTargetingSpecs = () => {
        setLoading('general');

        campaignsModel.getTargetingSpecs().then((response) => {
            if (response !== false) {
                setTargetingSpecs(response);
            }
        }).finally(() => {
            setLoading('');
        });
    }


    const refreshTargetingSpecs = () => {
        setLoading('refresh');

        campaignsModel.refreshTargetingSpecs().then((response) => {
            if (response !== false) {
                setTargetingSpecs(response);
            }
        }).finally(() => {
            setLoading('');
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Loading error', 'error');
        } else if (response.data && response.data.error) {
            notifications.notify(response.data.error, 'error');
        }
    }


    const onSubmitClick = () => {
        setLoading(true);

        campaignsModel.createCampaign(uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Campaign created', 'success');

                if (response.adsetId) {
                    setAdsetId(response.adsetId);
                }

                syncProject();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const syncProject = () => {
        setLoading(true);

        projectsModel.sync(projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Campaigns synced', 'success');

                window.location.href = '/p/' + projectId + '/campaigns';
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    useEffect(() => {
        loadData();
    }, []);


    // noinspection JSAnnotator
    return (
        <>
            <div className="d-flex flex-row justify-content-between m-block-gap">
                <Link to={'/p'} className="btn btn-light-primary text-primary">Back to the projects list</Link>

                <ButtonWithLoading
                    className={'btn-light-success text-success'}
                    label={'Refresh targeting specs'}
                    loading={loading === 'refresh'}
                    noOriginalIconClassName={true}
                    iconClassName={'ti fs-4 me-2 ti-reload'}
                    onClick={refreshTargetingSpecs}
                />
            </div>

            {adsetId ? (
                <div>Syncing data...</div>
            ) : (
            <SimpleCardContainer title="New campaign creating..." loading={loading === 'general'}>
                {Object.keys(targetingSpecs).length ? (
                    <div>
                        <h5>Targeting specs</h5>
                        <div>
                            {Object.keys(targetingSpecs).map((spec, index) => {
                                const title = spec.charAt(0).toUpperCase() + spec.slice(1).replace('_', ' ');
                                const data = targetingSpecs[spec];

                                return (
                                    <div key={'spec-' + index}>
                                        <h6>{title}</h6>
                                        <pre>
                                            {JSON.stringify(data, null, 2)}
                                        </pre>
                                    </div>
                                );
                            })}
                        </div>

                        <ButtonWithLoading
                            className={'btn-primary'}
                            label={'Create a campaign'}
                            loading={loading === 'general'}
                            noOriginalIconClassName={true}
                            iconClassName={'ti fs-5 me-2 ti-device-floppy'}
                            onClick={onSubmitClick}
                        />
                    </div>
                ) : 'Fetching data...'}
            </SimpleCardContainer>
            )}
        </>
    );
}