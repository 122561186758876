import React, {useEffect, useRef} from 'react';

import {useNotifications} from "../../../providers/NotificationsProvider";
import {useApi} from "../../../providers/ApiProvider";
import {Link, useParams} from "react-router-dom";
import CampaignsModel from "../../../models/CampaignsModel";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import Grades from "../../../helpers/Grades";
import {useMeta} from "../../../providers/MetaProvider";
import ProjectsModel from "../../../models/ProjectsModel";
import ProjectRulesModel from "../../../models/ProjectRulesModel";

export default function ProjectRulesCreate({}) {
    const metaContext = useMeta();
    const params = useParams();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const projectsRulesModel = new ProjectRulesModel(apiContext.api, params.projectId);
    const notifications = useNotifications();

    const [project, setProject] = React.useState({});
    const [ruleName, setRuleName] = React.useState('');
    const [ruleDescription, setRuleDescription] = React.useState('');
    const [ruleAction, setRuleAction] = React.useState('');
    const [ruleVariable, setRuleVariable] = React.useState('');
    const [ruleCondition, setRuleCondition] = React.useState('');
    const [ruleValue, setRuleValue] = React.useState('');
    const [ruleIsActive, setRuleIsActive] = React.useState(true);
    const [ruleSpecs, setRuleSpecs] = React.useState({
        actions: {},
        variables: {},
        conditions: {}
    });
    const [loading, setLoading] = React.useState(true);


    const loadData = () => {
        setLoading(true);

        projectsModel.view(params.projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                setProject(response);

                metaContext.update({
                    title: response.title + ' - Create a Rule'
                });
            }

            loadSpecifications().finally(() => {
                setLoading(false);
            });
        });
    }


    const loadSpecifications = () => {
        return projectsRulesModel.specifications().then((response) => {
            if (response !== false) {
                setRuleSpecs(response);
            }
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Loading error', 'error');
        }
    }


    useEffect(() => {
        loadData();
    }, []);


    const validate = () => {
        if (!ruleName) {
            notifications.notify('Name is required', 'error');
            return false;
        }

        if (!ruleAction) {
            notifications.notify('Action is required', 'error');
            return false;
        }

        if (!ruleVariable) {
            notifications.notify('Variable is required', 'error');
            return false;
        }

        if (!ruleCondition) {
            notifications.notify('Condition is required', 'error');
            return false;
        }

        if (!ruleValue) {
            notifications.notify('Value is required', 'error');
            return false;
        }

        return true;
    }


    const onSubmit = (e) => {
        if (validate() === false) {
            return;
        }

        const data = {
            project_id: params.projectId,
            name: ruleName,
            description: ruleDescription,
            action: ruleAction,
            variable: ruleVariable,
            condition: ruleCondition,
            value: ruleValue,
            is_active: ruleIsActive ? 1 : 0
        };

        projectsRulesModel.create(data).then((response) => {
            if (response !== false) {
                notifications.notify('Rule created', 'success');

                window.location.href = '/p/' + params.projectId + '/rules';
            }
        });
    }


    return (
        <>
            <div className="d-flex flex-row justify-content-start m-block-gap">
                <Link to={'/p/'+params.projectId+'/rules'} className="btn btn-light-primary text-primary">Back to the list</Link>
            </div>

            <h1>Create a new rule</h1>

            <div className="d-flex flex-column gap-3" style={{maxWidth: '600px'}}>
                <div className="form-group">
                    <label htmlFor="form-name">Name</label>
                    <input type="text" id="form-name" className="form-control" value={ruleName} onChange={(e) => setRuleName(e.target.value)}/>
                </div>

                <div className="form-group">
                    <label htmlFor="form-description">Description</label>
                    <input type="text" id="form-description" className="form-control" value={ruleDescription}
                           onChange={(e) => setRuleDescription(e.target.value)}/>
                </div>

                <div className="d-flex flex-row gap-3 align-items-stretch">
                    <div className="form-group w-100">
                        <label htmlFor="form-variable">Metric</label>
                        <select id="form-variable" className="form-select" value={ruleVariable} onChange={(e) => setRuleVariable(e.target.value)}>
                            <option value={''}>Select a metric...</option>
                            {Object.keys(ruleSpecs.variables).map((variable) => (
                                <option key={variable} value={variable}>{ruleSpecs.variables[variable]}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group w-100">
                        <label htmlFor="form-condition">Condition</label>
                        <select id="form-condition" className="form-select" value={ruleCondition} onChange={(e) => setRuleCondition(e.target.value)}>
                            <option value={''}>Select a condition...</option>
                            {Object.keys(ruleSpecs.conditions).map((condition) => (
                                <option key={condition} value={condition}>{ruleSpecs.conditions[condition]}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group w-100">
                        <label htmlFor="form-value">Value</label>
                        <input type="text" id="form-value" className="form-control" value={ruleValue} onChange={(e) => setRuleValue(e.target.value)}/>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="form-action">Action</label>
                    <select id="form-action" className="form-select" value={ruleAction} onChange={(e) => setRuleAction(e.target.value)}>
                        <option value={''}>Select an action...</option>
                        {Object.keys(ruleSpecs.actions).map((actionGroup) => (
                            <optgroup key={actionGroup} label={actionGroup}>
                                {Object.keys(ruleSpecs.actions[actionGroup]).map((actionKey) => (
                                    <option key={actionKey} value={actionKey}>{ruleSpecs.actions[actionGroup][actionKey]}</option>
                                ))}
                            </optgroup>
                        ))}
                    </select>
                </div>

                <div className="form-check">
                    <input className="form-check-input primary" type="checkbox" id="split-checkbox" checked={ruleIsActive} onChange={(e) => setRuleIsActive(e.target.checked)}/>
                    <label className="form-check-label text-dark" htmlFor="split-checkbox">
                        Active
                    </label>
                </div>

                <button className="btn btn-primary" onClick={onSubmit}>Create</button>
            </div>
        </>
    );
}