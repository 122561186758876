import React, {useEffect, useRef} from 'react';

import {useApi} from "../../providers/ApiProvider";
import {Link, useParams} from "react-router-dom";
import {useNotifications} from "../../providers/NotificationsProvider";
import ProjectsModel from "../../models/ProjectsModel";
import ProjectTitle from "./common/ProjectTitle";
import CampaignsModel from "../../models/CampaignsModel";
import CampaignsList from "../campaigns/CampaignsList";
import {useMeta} from "../../providers/MetaProvider";
import ProjectHighlights from "./common/ProjectHighlights";
import ProjectInterests from "./common/ProjectInterests";

export default function ProjectView({}) {
    const metaContext = useMeta();
    const params = useParams();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const campaignsModel = new CampaignsModel(apiContext.api, params.projectId);
    const notifications = useNotifications();

    const [project, setProject] = React.useState({});
    const [campaigns, setCampaigns] = React.useState([]);
    const [loading, setLoading] = React.useState(['general']);



    useEffect(() => {
        getProjectInfo().then(() => {
            getCampaignsInfo();
        });
    }, []);


    const getProjectInfo = () => {
        if (!loading.includes('general')) {
            setLoading([...loading, 'general']);
        }

        return projectsModel.view(params.projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                metaContext.update({
                    title: response.title
                });

                setProject(response);
                setCampaigns(response.campaigns ? response.campaigns : []);
            }
        }).finally(() => {
            setLoading(loading.filter((item) => item !== 'general'));
        });
    }


    const getCampaignsInfo = () => {
        if (!loading.includes('campaigns')) {
            setLoading([...loading, 'campaigns']);
        }

        return campaignsModel.list().then((response) => {
            if (response !== false) {
                setCampaigns(response);
            }
        }).finally(() => {
            setLoading(loading.filter((item) => item !== 'campaigns'));
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Loading error!', 'error');
        }
    }


    const onRefresh = () => {
        if (!loading.includes('refresh')) {
            setLoading([...loading, 'refresh']);
        }

        projectsModel.sync(project.id, uploadErrorHandler).then((response) => {
            if (response !== false) {
                metaContext.update({
                    title: response.title
                });

                setProject(response);
                setCampaigns(response.campaigns ? response.campaigns : []);
            }
        }).finally(() => {
            setLoading(loading.filter((item) => item !== 'refresh'));
        });
    }


    const onExport = () => {
        if (!loading.includes('export')) {
            setLoading([...loading, 'export']);
        }

        projectsModel.export(project.id, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Exported!', 'success');
            }

            const blob = new Blob([JSON.stringify(response, null, 2)], { type: 'application/json' });
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'export.json';
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }).finally(() => {
            setLoading(loading.filter((item) => item !== 'export'));
        });
    }


    return project.id ? (<>
        <ProjectTitle
            loading={loading}
            project={project}
            onRefresh={onRefresh}
            onExport={onExport}
        />

        <div className="d-flex flex-row gap-4 justify-content-start px-3 mb-4">
            <Link to={'/p/' + params.projectId + '/campaigns/create'} className="btn btn-light-success text-success">Create a Campaign</Link>
            <Link to={'/p/' + params.projectId + '/campaigns'} className="btn btn-light-primary text-primary">Campaigns</Link>
            <Link to={'/p/' + params.projectId + '/audiences'} className="btn btn-light-primary text-primary">Audiences</Link>
            <Link to={'/p/' + params.projectId + '/rules'} className="btn btn-light-primary text-primary">Automation Rules</Link>
        </div>

        <div className="row">
            <div className="col-lg-6">
                <ProjectHighlights
                    project={project}
                    campaigns={campaigns}
                />
            </div>
        </div>
    </>) : '';
}
